import React, { useState } from "react";

export default function useStickyState(defaultValue, key) {
  const isBrowser = typeof window !== "undefined";

  const [value, setValue] = useState(() => {
    const stickyValue = window.localStorage.getItem(key);
    if (isBrowser) {
      return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
    }
  });
  React.useEffect(() => {
    if (isBrowser) {
      window.localStorage.setItem(key, JSON.stringify(value));
    }
  }, [key, value]);
  return [value, setValue];
}
