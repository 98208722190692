import React from "react";
import RegionModal from "./src/components/RegionModal";

export function wrapRootElement({ element }) {
  return (
    <>
      {element}

      <RegionModal />
    </>
  );
}
